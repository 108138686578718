import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationState, organizationAdapter } from './organization.state';
import { Organization } from '@models';

export const ORGANIZATION_STATE_NAME = 'organization';
const selectOrganizationState = createFeatureSelector<OrganizationState>(
  ORGANIZATION_STATE_NAME
);
export const organizationSelector = organizationAdapter.getSelectors();
export const selectOrganizations = createSelector(
  selectOrganizationState,
  organizationSelector.selectAll
);
export const selectOrganizationEntities = createSelector(
  selectOrganizationState,
  organizationSelector.selectEntities
);
export const selectOrganizationById = (id: Organization['id']) =>
  createSelector(selectOrganizationEntities, organizations =>
    organizations ? organizations[id] : null
  );
export const selectOrganizationIsLoading = createSelector(
  selectOrganizationState,
  state => state.isLoading
);
